import { SetupStep } from '../gettingstarted/types';

export const getSteps = (): SetupStep[] => [
  {
    heading: 'Welcome to Sensetif',
    subheading: 'The steps below will guide you to quickly finish setting up your Grafana installation.',
    title: 'Getting Started',
    info: 'These steps will guide you to get going with your Sensetif experience.',
    done: false,
    cards: [
      {
        type: 'tutorial',
        heading: 'projects',
        title: 'Create your first project with a subsystem and datapoints in it.',
        icon: 'panel-add',
        href: '/a/sensetif-app?tab=new_project',
        learnHref: 'https://sensetif.com/docs/guide/getting_started',
        check: async () => {
          return false;
        },
        done: false,
        info: '',
        key: 'step1c1'
      },
      {
        type: 'tutorial',
        heading: 'dashboards',
        title: 'Create your first dashboard',
        icon: 'apps',
        href: 'dashboard/new',
        learnHref: 'https://sensetif.com/docs/guides/dashboards.html',
        check: async () => {
          return false;
        },
        done: false,
        info: '',
        key: 'step1c2'
      },
      {
        type: 'tutorial',
        heading: 'The Things Network',
        title:
          'The Things Network is integrated into the Datapoint creation. Instead of polling a URL, you can specify a zone + application + device + value to be monitored and fetched. More ways will follow soon.',
        icon: 'sync',
        href: '',
        learnHref: 'https://sensetif.com/docs/guide/thethingsnetwork.html',
        check: async () => {
          return false;
        },
        done: false,
        info: '',
        key: 'step1c3'
      }
      // {
      //   type: 'tutorial',
      //   heading: 'plans',
      //   title: 'Sign up for a Plan to expand your system.',
      //   icon: 'power',
      //   href: '/a/sensetif-app?tab=plans',
      //   learnHref: 'https://sensetif.com/docs/guide/plans.html',
      //   check: async () => {
      //     return false;
      //   },
      //   done: false,
      //   info: '',
      //   key: 'step1c4'
      // }
    ]
  }
];
